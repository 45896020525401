import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import { styled, alpha, useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(
    theme.palette.mode === "light" ? "#095FB5" : "#ffff",
    theme.palette.mode === "light" ? 0.6 : 0.15
  ),
  "&:hover": {
    backgroundColor: alpha(
      theme.palette.mode === "light" ? "#095FB5" : "#ffff",
      theme.palette.mode === "light" ? 0.7 : 0.25
    ),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "white",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function AppBarCustom(props) {
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() !== "") {
      setOpen(false);
      navigate(`/search?q=${searchQuery}`);
    }
  };

  React.useEffect(() => {
    fetch("https://www.dailyblend.online/api/category")
      .then((res) => res.json())
      .then((users) => setCategory(users))
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,

            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",

            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              ml: "-18px",
              px: 0,
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <img
              src={
                theme.palette.mode === "light"
                  ? "../dailyBlendLogoLight.png"
                  : "../dailyBlendLogoDark.png"
              }
              style={logoStyle}
              alt="logo of dailyblend"
              onClick={handleLogoClick}
            />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {category.map((item) => (
                <MenuItem
                  key={item.Name}
                  sx={{ py: "6px", px: "12px" }}
                  onClick={() => handleNavigation(item.Name.toLowerCase())}
                >
                  <Typography variant="body2" color="text.primary">
                    {item.Name}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <ToggleColorMode />
            <form onSubmit={handleSearchSubmit}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon
                    onClick={handleSearchSubmit}
                    sx={{ cursor: "pointer" }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </Search>
            </form>
          </Box>
          <Box sx={{ display: { sm: "", md: "none" } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: "30px", p: "4px" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: "60dvw",
                  p: 2,
                  backgroundColor: "background.paper",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    flexGrow: 1,
                  }}
                >
                  <ToggleColorMode />
                </Box>
                {category.map((item) => (
                  <MenuItem
                    key={item.Name}
                    onClick={() => handleNavigation(item.Name.toLowerCase())}
                  >
                    {item.Name}
                  </MenuItem>
                ))}
                <Divider />

                <form onSubmit={handleSearchSubmit}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon
                        onClick={handleSearchSubmit}
                        sx={{ cursor: "pointer" }}
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </Search>
                </form>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppBarCustom;
