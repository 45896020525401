import { createSlice } from "@reduxjs/toolkit";
import themeReducers from "../reducers/themeReducers";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: "light", // initial mode can be "light" or "dark"
  },
  reducers: themeReducers,
});

export const { toggleColorMode } = themeSlice.actions;

export default themeSlice.reducer;
