import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FaDog } from "react-icons/fa"; // Import dog emoji from react-icons
import "../css/NotFound.css";

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JGB7KL1LMW"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-JGB7KL1LMW');
    `}
        </script>
        <title>404 - Page Not Found</title>
      </Helmet>
      <h1>404 - Page Not Found</h1>
      <p>
        Sorry, the page you are looking for does not exist. <FaDog />
      </p>{" "}
      {/* Dog emoji */}
      <p>
        You can go back to the <Link to="/">homepage</Link> or use the
        navigation menu to find what you need.
      </p>
      <p>Here are some helpful links:</p>
      <ul>
        <li>
          <Link to="/technology">Technology</Link>
        </li>
        <li>
          <Link to="/gadgets">Gadgets</Link>
        </li>
        <li>
          <Link to="/fashion">Fashion</Link>
        </li>
      </ul>
    </div>
  );
};

export default NotFoundPage;
