import { useSelector } from "react-redux";
import "./App.css";
import Home from "./screens/Home";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import getLPTheme from "./components/getLPTheme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReadMore from "./screens/ReadMore";
import Technology from "./screens/Technology";
import Gadgets from "./screens/Gadegts";
import SearchResultsPage from "./components/SearchResultsPage";
import NotFoundPage from "./screens/NotFound";
import Fashion from "./screens/Fashion";

function App() {
  const mode = useSelector((state) => state.theme.mode);
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Route for Home */}
          <Route path="/" element={<Home />}>
            {/* Nested Routes under Home */}
            <Route path="/read-more/:id" element={<ReadMore />} />
            <Route path="technology" element={<Technology />} />
            <Route path="gadgets" element={<Gadgets />} />
            <Route path="fashion" element={<Fashion />} />
            <Route path="search" element={<SearchResultsPage />} />
          </Route>

          {/* Catch-all Route for NotFoundPage */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
