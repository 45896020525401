import * as React from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function Hero() {
  return (
    <>
      <Helmet>
        <title>
          Discover What's New - Latest Blogs on Tech, Fashion, and More
        </title>
        <meta
          name="description"
          content="Stay updated with our latest blog posts on tech, fashion, home decor, and more. Subscribe now to get daily updates straight to your inbox!"
        />
        <meta
          name="keywords"
          content="blog, tech, fashion, home decor, updates, subscribe, latest jobs, product reviews, react, angular"
        />
        <meta
          property="og:title"
          content="Discover What's New - Latest Blogs on Tech, Fashion, and More"
        />
        <meta
          property="og:description"
          content="Stay updated with our latest blog posts on tech, fashion, home decor, and more. Subscribe now to get daily updates straight to your inbox!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dailyblend.online/" />
        {/* Replace with actual URL */}
        <meta
          property="og:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content="Discover What's New - Latest Blogs on Tech, Fashion, and More"
        />
        <meta
          property="twitter:description"
          content="Stay updated with our latest blog posts on tech, fashion, home decor, and more. Subscribe now to get daily updates straight to your inbox!"
        />
        <meta property="twitter:url" content="https://dailyblend.online/" />
        {/* Replace with actual URL */}
        <meta
          property="twitter:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        {/* Replace with actual image URL */}
      </Helmet>

      <Box id="hero">
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 10, sm: 13 },
            pb: { xs: 8, sm: 0 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ width: { xs: "100%", sm: "70%" } }}
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "center",
                textAlign: "center",
                fontSize: "clamp(3.5rem, 10vw, 4rem)",
              }}
            >
              Check Out&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontSize: "clamp(3rem, 10vw, 4rem)",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                }}
              >
                What's New!
              </Typography>
            </Typography>
            <Typography
              textAlign="center"
              color="text.secondary"
              sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
            >
              We've got exciting new blogs every day just for you. Whether
              you're into tech, fashion, home decor, or more, there's something
              fresh waiting to be discovered. Subscribe now to get daily updates
              straight to your inbox!
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
            >
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="Your email address"
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                }}
              />
              <Button variant="contained" color="primary">
                Start now
              </Button>
            </Stack>
            <Typography
              variant="caption"
              textAlign="center"
              sx={{ opacity: 0.8 }}
            >
              By clicking &quot;Start now&quot; you agree to our&nbsp;
              <Link href="#" color="primary">
                Terms & Conditions
              </Link>
              .
            </Typography>
          </Stack>
        </Container>

        {/* New Sections */}
        <Container sx={{ py: { xs: 8, sm: 10 } }}>
          {/* Introduction Section */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography variant="h2" gutterBottom>
              Welcome to Our Blog
            </Typography>
            <Typography color="text.secondary">
              Discover the latest trends, tips, and insights on a variety of
              topics from our team of expert writers. Stay informed and
              entertained with our daily updates. Our blog covers a wide range
              of categories including technology, fashion, home decor, and more,
              ensuring there's something for everyone.
            </Typography>
          </Box>

          {/* Feature Highlights */}
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={4}
            sx={{ mb: 6 }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography variant="h3" gutterBottom>
                Tech Innovations
              </Typography>
              <Typography color="text.secondary">
                Stay updated with the latest advancements in technology. From
                artificial intelligence and machine learning to the latest
                gadgets and software updates, we cover it all to keep you
                informed about the tech world. Our tech articles are designed to
                help you understand and embrace the future of technology.
              </Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography variant="h3" gutterBottom>
                Fashion Trends
              </Typography>
              <Typography color="text.secondary">
                Explore the newest styles and fashion tips. Stay ahead in the
                fashion game with our expert advice and trend analysis. From
                seasonal fashion trends to timeless wardrobe essentials, our
                fashion section offers insights and inspiration for every
                fashion enthusiast.
              </Typography>
            </Box>
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography variant="h3" gutterBottom>
                Home Decor Ideas
              </Typography>
              <Typography color="text.secondary">
                Get inspired by our home decor suggestions. From modern
                minimalism to cozy comfort, find ideas that suit your style. Our
                home decor articles cover everything from DIY projects and
                interior design tips to the latest trends in home furnishings
                and decor.
              </Typography>
            </Box>
          </Stack>

          {/* Latest Job Postings */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography variant="h2" gutterBottom>
              Latest Job Postings
            </Typography>
            <Typography color="text.secondary">
              Explore new job opportunities curated for you. Stay ahead in your
              career with our regularly updated job listings and career advice.
              Whether you're looking for a job in technology, marketing, design,
              or any other field, our job section provides valuable resources
              and guidance to help you land your dream job.
            </Typography>
          </Box>

          {/* Product Reviews */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography variant="h2" gutterBottom>
              Latest Product Reviews
            </Typography>
            <Typography color="text.secondary">
              Read our latest product reviews and find out what’s worth buying.
              Our unbiased reviews will help you make informed purchase
              decisions. From electronics and gadgets to fashion and beauty
              products, our detailed reviews cover a wide range of products to
              help you choose the best options available.
            </Typography>
          </Box>

          {/* Latest Blog Posts */}
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography variant="h2" gutterBottom>
              Latest Blog Posts
            </Typography>
            <Typography color="text.secondary">
              Catch up on our newest articles and insights. Stay informed and
              entertained with our engaging and informative content. Our blog
              posts cover a variety of topics, offering fresh perspectives and
              valuable information to keep you updated and engaged.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
