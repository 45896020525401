import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardComponent from "../components/CardComponent";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Fashion() {
  const [post, setPost] = React.useState([]);

  React.useEffect(() => {
    fetch("https://www.dailyblend.online/api/fashionPosts")
      .then((res) => res.json())
      .then((posts) => setPost(posts))
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  if (post.length === 0) return <div>Loading...</div>;

  const structuredData = post?.map((reviewData, index) => ({
    "@context": "https://schema.org",
    "@type": "Fashion Blog",
    itemReviewed: {
      "@type": "BlogPosting",
      headline: reviewData.Title,
      image: reviewData.Image,
      description: reviewData.ShortDescription,
      author: {
        "@type": "Person",
        name: reviewData.AuthorName,
      },
      datePublished: reviewData.DatePosted,
    },
    author: {
      "@type": "Person",
      name: reviewData.AuthorName,
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: reviewData.RatingValue || 5,
      bestRating: reviewData.BestRating || 5,
      worstRating: reviewData.WorstRating || 1,
    },
    reviewBody: reviewData.Content,
  }));

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JGB7KL1LMW"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-JGB7KL1LMW');
    `}
        </script>
        <title>Fashion Blog Posts - Latest Fashion Trends & Style Tips</title>
        <meta
          name="description"
          content="Stay up-to-date with the latest fashion trends, style tips, and beauty advice from our fashion blog. Discover the newest fashion collections, outfit ideas, and more."
        />
        <meta
          name="keywords"
          content="fashion, fashion trends, style tips, beauty advice, outfit ideas, fashion blog, latest fashion, fashion news, style guide"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Fashion Blog Posts - Latest Fashion Trends & Style Tips"
        />
        <meta
          property="og:description"
          content="Stay up-to-date with the latest fashion trends, style tips, and beauty advice from our fashion blog. Discover the newest fashion collections, outfit ideas, and more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="http://www.dailyblend.online/fashion"
        />
        <meta
          property="og:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        <meta property="og:site_name" content="Daily Blend" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Fashion Blog Posts - Latest Fashion Trends & Style Tips"
        />
        <meta
          name="twitter:description"
          content="Stay up-to-date with the latest fashion trends, style tips, and beauty advice from our fashion blog. Discover the newest fashion collections, outfit ideas, and more."
        />
        <meta
          name="twitter:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Blog",
            url: "https://www.dailyblend.online/fashion",
            name: "Fashion Blog Posts - Latest Fashion Trends & Style Tips",
            description:
              "Stay up-to-date with the latest fashion trends, style tips, and beauty advice from our fashion blog. Discover the newest fashion collections, outfit ideas, and more.",
            author: {
              "@type": "Person",
              name: "Daily Blend",
            },
            publisher: {
              "@type": "Organization",
              name: "Daily Blend",
              logo: {
                "@type": "ImageObject",
                url: "https://dailyblend.online/dailyBlendLogoF.png",
              },
            },
            image: "https://dailyblend.online/dailyBlendLogoF.png",
          })}
        </script>
        {structuredData.map((data, index) => {
          return (
            <script key={index} type="application/ld+json">
              {JSON.stringify(data)}
            </script>
          );
        })}
        <script
          async="async"
          data-cfasync="false"
          src="//pl23571129.highrevenuenetwork.com/87d6368575303aee4770457f47b672b7/invoke.js"
        ></script>
      </Helmet>

      <Container
        id="Recent"
        sx={{
          pt: { xs: 10, sm: 16 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "70%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h1" variant="h4" color="text.primary">
            Fashion Blog Posts
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Welcome to our Fashion Paradise! 🌟 Dive into the world of fashion
            with us, where we bring you the latest trends, style tips, and
            beauty advice. Whether you're a fashionista or just looking for some
            outfit inspiration, our easy-to-read articles, reviews, and tips
            will keep you in vogue and ahead of the style game. Join our fashion
            community and stay chic and stylish with us! 💃✨
          </Typography>
        </Box>

        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Latest Fashion Trends
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Our fashion blog is your ultimate source for the latest fashion
                trends. Stay informed about the newest collections, seasonal
                styles, and runway highlights. We provide in-depth articles that
                cover the latest fashion movements and how to incorporate them
                into your wardrobe.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                In-Depth Style Guides
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Discover the best style tips and outfit ideas for every
                occasion. From casual chic to evening glam, our detailed style
                guides provide you with all the inspiration you need to look
                your best. We break down the essentials of fashion and how to
                create stunning looks with ease.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Beauty and Skincare Tips
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Explore our comprehensive guides on beauty and skincare. Whether
                you're looking for the latest beauty products, skincare
                routines, or makeup tutorials, our guides offer step-by-step
                instructions, tips, and recommendations to help you achieve your
                best look.
              </Typography>
            </Box>
          </Grid>
          <div id="container-87d6368575303aee4770457f47b672b7"></div>
        </Grid>

        <Grid container spacing={2} mt={4}>
          {post[0] !== "No results found" &&
            post.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
                  <CardComponent
                    title={item.Title}
                    shortDescription={item.ShortDescription}
                    date={item.DatePosted}
                    author={item.AuthorName}
                    authorBio={item.AuthorBio}
                    content={item.Content}
                    authorDesc={item.AuthorDesc}
                    id={item.URL}
                    imageUrl={item.Image}
                  />
                </Grid>
                {(index + 1) % 3 === 0 && (
                  <Grid
                    item
                    xs={12}
                    key={`container-${index}`}
                    id={`container-${index}`}
                  >
                    {/* Replace `key` and `id` as needed */}
                    {/* This div will be rendered after every 3rd CardComponent */}
                    <div id="container-87d6368575303aee4770457f47b672b7"></div>
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>
      </Container>
    </>
  );
}
