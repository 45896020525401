import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardComponent from "../components/CardComponent";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Recents() {
  const [post, setPost] = React.useState([]);

  React.useEffect(() => {
    fetch("https://www.dailyblend.online/api/posts")
      .then((res) => res.json())
      .then((posts) => setPost(posts))
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  if (post.length === 0) return <div>Loading...</div>;

  const structuredData = post.map((reviewData, index) => ({
    "@context": "https://schema.org",
    "@type": "Blog",
    itemReviewed: {
      "@type": "BlogPosting",
      headline: reviewData.Title,
      image: reviewData.Image,
      description: reviewData.ShortDescription,
      author: {
        "@type": "Person",
        name: reviewData.AuthorName,
      },
      datePublished: reviewData.DatePosted,
    },
    author: {
      "@type": "Person",
      name: reviewData.AuthorName,
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: reviewData.RatingValue || 5,
      bestRating: reviewData.BestRating || 5,
      worstRating: reviewData.WorstRating || 1,
    },
    reviewBody: reviewData.Content,
  }));

  return (
    <Container
      id="Recent"
      sx={{
        pt: { xs: 4, sm: 0 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Helmet>
        <meta
          name="description"
          content="DailyBlend: Your daily dose of tech, current affairs, fashion, and academic insights. Explore vibrant articles curated to inform, inspire, and engage."
        />
        <meta
          name="keywords"
          content="DailyBlend, tech, technology, current affairs, fashion, fashion trends, academic insights, articles, eclectic content, daily dose, informative, inspirational, engaging"
        />

        <meta
          property="og:title"
          content="DailyBlend - Your Daily Dose of Eclectic Content"
        />
        <meta
          property="og:description"
          content="Explore tech, current affairs, fashion, and academic insights on DailyBlend. Informative, inspirational, and engaging articles all in one place."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dailyblend.online/" />
        <meta
          property="og:image"
          content="https://www.dailyblend.online/dailyBlendLogoLight.png"
        />
        <meta property="og:site_name" content="DailyBlend" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="DailyBlend - Your Daily Dose of Eclectic Content"
        />
        <meta
          name="twitter:description"
          content="Explore tech, current affairs, fashion, and academic insights on DailyBlend. Informative, inspirational, and engaging articles all in one place."
        />
        <meta
          name="twitter:image"
          content="https://www.dailyblend.online/dailyBlendLogoLight.png"
        />
        <meta name="twitter:site" content="@blend_dail80961" />

        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://www.dailyblend.online/sitemap.xml"
        />

        <title>Latest Blog Posts | Insightful Articles & Tips</title>
        {structuredData.map((data, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(data)}
          </script>
        ))}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JGB7KL1LMW"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-JGB7KL1LMW');
        `}
        </script>
        <script type="text/javascript">
          {`
          atOptions = {
            'key': '073eb83b14d2fe239b1918840e1c398c',
            'format': 'iframe',
            'height': 60,
            'width': 468,
            'params': {}
          };
        `}
        </script>
        <script
          type="text/javascript"
          src="//www.topcreativeformat.com/073eb83b14d2fe239b1918840e1c398c/invoke.js"
        ></script>
        <script
          async="async"
          data-cfasync="false"
          src="//pl23571129.highrevenuenetwork.com/87d6368575303aee4770457f47b672b7/invoke.js"
        ></script>
      </Helmet>

      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Recent Blog Posts
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Dive into our latest blog entries to stay updated on the most current
          trends, tips, and insights across various topics. From in-depth
          analyses to quick how-tos, our recent posts are crafted to keep you
          informed and inspired. Explore now and enhance your knowledge with
          fresh content tailored just for you!
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {post.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <CardComponent
              title={item.Title}
              shortDescription={item.ShortDescription}
              date={item.DatePosted}
              author={item.AuthorName}
              authorBio={item.AuthorBio}
              index={index}
              content={item.Content}
              authorDesc={item.AuthorDesc}
              id={item.URL}
              imageUrl={item.Image}
              pageTitle={item.PageTitle}
              postMetaDesc={item.MetaDescription}
            />
          </Grid>
        ))}
        <div id="container-87d6368575303aee4770457f47b672b7"></div>
      </Grid>
    </Container>
  );
}
