import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Markdown from "./Markdown";
import ShareIcon from "@mui/icons-material/Share"; // Import Share icon from Material-UI
import { RWebShare } from "react-web-share";

function Main(props) {
  const { posts, title, date, author, imageUrl } = props;

  return (
    <Grid
      container
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
      spacing={2}
    >
      {/* Title and Author Section */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          <b>{title}</b>
        </Typography>
        <p style={{ margin: "0px", fontStyle: "italic", display: "flex" }}>
          {date} by &nbsp;
          <a
            href="https://www.twitter.com/Rockk64"
            target="_blank"
            rel="noreferrer"
          >
            {author}
          </a>
          <span style={{ marginLeft: "10px" }}>
            <RWebShare
              data={{
                title: title,
                text: `Check out this article by ${author} on DailyBlend: ${title}`,
                url: window.location.href,
              }}
            >
              <ShareIcon sx={{ cursor: "pointer" }} />
            </RWebShare>
          </span>
        </p>
        <Divider sx={{ bgcolor: "secondary.light", my: 2 }} />
      </Grid>

      {/* Image Section (Assuming you have an image URL stored in 'imageUrl') */}
      <Grid item xs={12}>
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: "100%",
            height: "300px", // Adjust this height as needed
            objectFit: "cover",
            borderRadius: 8,
            maxHeight: "100%", // Ensure it doesn't exceed its container's height
          }}
        />
      </Grid>

      {/* Content Section */}
      <Grid item xs={12}>
        <Markdown className="markdown">{posts}</Markdown>
      </Grid>
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default Main;
