import React, { useState, useEffect } from "react";
import { Container, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import Sidebar from "./Sidebar";
import Main from "./Main";
import { Helmet } from "react-helmet";
import NotFoundPage from "./NotFound";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const sidebar = {
  social: [
    { name: "GitHub", icon: GitHubIcon },
    { name: "X", icon: XIcon },
    { name: "Facebook", icon: FacebookIcon },
  ],
};

function ReadMore() {
  const { id } = useParams();
  const [postDesc, setPostDesc] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://www.dailyblend.online/api/postsDesc/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setPostDesc(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching post description:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", pt: 8 }}>
        <CircularProgress />
      </Container>
    );
  }

  const dateF = new Date(postDesc.DatePosted);
  const month = monthNames[dateF.getMonth()];
  const day = dateF.getDate();
  const year = dateF.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  const canonicalUrl = `https://www.dailyblend.online/post/${id}`;

  // Constructing JSON-LD structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": canonicalUrl,
    },
    headline: postDesc.Title,
    image: postDesc.Image,
    datePublished: postDesc.DatePosted,
    dateModified: postDesc.DateModified || postDesc.DatePosted,
    author: {
      "@type": "Person",
      name: postDesc.AuthorName,
    },
    publisher: {
      "@type": "Organization",
      name: "Daily Blend",
      logo: {
        "@type": "ImageObject",
        url: "https://dailyblend.online/dailyBlendLogoF.png",
      },
    },
    description: postDesc.MetaDescription,
  };

  return (
    <>
      <Helmet>
        <title>{postDesc.PageTitle || postDesc.Title}</title>
        <meta name="description" content={postDesc.MetaDescription || ""} />
        <link rel="canonical" href={canonicalUrl} />
        {/* Open Graph tags */}
        <meta
          property="og:title"
          content={`${postDesc.Title || ""} - DailyBlend`}
        />
        <meta
          property="og:description"
          content={postDesc.MetaDescription || ""}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={postDesc.Image || ""} />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${postDesc.Title || ""} - DailyBlend`}
        />
        <meta
          name="twitter:description"
          content={postDesc.MetaDescription || ""}
        />
        <meta name="twitter:image" content={postDesc.Image || ""} />
        <meta name="keywords" content={postDesc.MetaKeyword || ""} />
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 4, sm: 8 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <main>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            {postDesc.error && <NotFoundPage />}
            {!postDesc.error && (
              <>
                <Main
                  title={postDesc.Title}
                  posts={postDesc.Content}
                  date={formattedDate}
                  author={postDesc.AuthorName}
                  imageUrl={postDesc.Image}
                  pageTitle={postDesc.PageTitle}
                  postMetaDesc={postDesc.MetaDescription}
                  postKeyword={postDesc.MetaKeyword}
                />
                <Sidebar
                  title="About"
                  description={postDesc.AuthorDesc}
                  archives={[]} // Placeholder for archives data
                  social={sidebar.social} // Using the defined sidebar social links
                />
              </>
            )}
          </Grid>
        </main>
      </Container>
    </>
  );
}

export default ReadMore;
