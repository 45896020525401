import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "auto",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
}));

function Footer(props) {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* About Us */}
          <Grid item xs={12} sm={4} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="textSecondary">
              DailyBlend is your daily source of inspiration for healthy living,
              wellness tips, creative recipes, and lifestyle articles. Explore
              our curated content to discover new ways to enhance your daily
              routine and foster a healthier, more creative lifestyle.
            </Typography>
          </Grid>

          {/* Contact Us */}
          <Grid item xs={12} sm={4} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Email: admin@dailyblend.online
            </Typography>
          </Grid>

          {/* Follow Us */}
          <Grid item xs={12} sm={4} className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <IconButton
              href="https://www.instagram.com/dailyblendofficial/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://x.com/@blend_dail80961"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/dailyblendOfficial"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Typography variant="body2" color="textSecondary" align="center">
          {"© "}
          <Link
            color="inherit"
            href="https://dailyblend.online/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DailyBlend
          </Link>
          {new Date().getFullYear()}
          {". All rights reserved."}
        </Typography>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
