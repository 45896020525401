import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Paper, useTheme } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import Markdown from "../screens/Markdown";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SearchResultsPage = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/${id}`, {});
  };

  useEffect(() => {
    if (query) {
      setLoading(true);
      fetch(`https://www.dailyblend.online/api/search?q=${query}`)
        .then((res) => res.json())
        .then((data) => {
          setResults(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
          setLoading(false);
        });
    }
  }, [query]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        pt: { xs: 10, sm: 10 },
        pb: { xs: 8, sm: 0 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { sm: "left", md: "left" },
        }}
      >
        {/* Heading and Total Results */}
        <Typography variant="h4" gutterBottom>
          Search Results for "{query}"
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {results.length} results found
        </Typography>

        {results.length > 0 ? (
          results.map((result) => {
            const dateF = new Date(result.DatePosted);
            const month = monthNames[dateF.getMonth()];
            const day = dateF.getDate();
            const year = dateF.getFullYear();
            const formattedDate = `${month} ${day}, ${year}`;
            return (
              <Paper
                key={result.PostID}
                sx={{
                  padding: 2,
                  margin: 2,
                  background:
                    theme.palette.mode === "dark" ? "darkslategrey" : "white",
                  border: "ridge",
                }}
              >
                <span
                  onClick={() => handleNavigate(result.URL)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="h6">{result.Title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formattedDate} by {result.AuthorName}
                  </Typography>
                  <Typography variant="body1">
                    <Markdown className="markdown">
                      {result.ShortDescription}
                    </Markdown>
                  </Typography>
                </span>
              </Paper>
            );
          })
        ) : (
          <Paper
            sx={{
              padding: 2,
              margin: 2,
              background:
                theme.palette.mode === "dark" ? "darkslategrey" : "white",
              border: "ridge",
              textAlign: "center",
              color: theme.palette.mode === "dark" ? "#fff" : "#000",
            }}
          >
            <Typography variant="h6" gutterBottom>
              No results found 😞
            </Typography>
            <Typography variant="body1">
              We couldn't find any results for "{query}". Please try a different
              search term.
            </Typography>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default SearchResultsPage;
