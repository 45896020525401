import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

function Sidebar(props) {
  const { description, social, title } = props;
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <script
          async="async"
          data-cfasync="false"
          src="//pl23571129.highrevenuenetwork.com/87d6368575303aee4770457f47b672b7/invoke.js"
        ></script>
      </Helmet>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            bgcolor: theme.palette.mode === "light" ? "grey.200" : "#01182E",
          }}
        >
          <div id="container-87d6368575303aee4770457f47b672b7"></div>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </Paper>
        <div id="container-87d6368575303aee4770457f47b672b7"></div>
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Social
        </Typography>
        {social.map((network) => (
          <Link
            display="block"
            variant="body1"
            href="#"
            key={network.name}
            sx={{ mb: 0.5 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <network.icon />
              <span>{network.name}</span>
            </Stack>
          </Link>
        ))}
        <div id="container-87d6368575303aee4770457f47b672b7"></div>
      </Grid>
    </>
  );
}

Sidebar.propTypes = {
  description: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Sidebar;
