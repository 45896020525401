import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardComponent from "../components/CardComponent";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Technology() {
  const [post, setPost] = React.useState([]);

  React.useEffect(() => {
    fetch("https://www.dailyblend.online/api/technologyPosts")
      .then((res) => res.json())
      .then((posts) => setPost(posts))
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  if (post.length === 0) return <div>Loading...</div>;

  const structuredData = post.map((reviewData, index) => ({
    "@context": "https://schema.org",
    "@type": "Technology Blog",
    itemReviewed: {
      "@type": "BlogPosting",
      headline: reviewData.Title,
      image: reviewData.Image,
      description: reviewData.ShortDescription,
      author: {
        "@type": "Person",
        name: reviewData.AuthorName,
      },
      datePublished: reviewData.DatePosted,
    },
    author: {
      "@type": "Person",
      name: reviewData.AuthorName,
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: reviewData.RatingValue || 5,
      bestRating: reviewData.BestRating || 5,
      worstRating: reviewData.WorstRating || 1,
    },
    reviewBody: reviewData.Content,
  }));

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JGB7KL1LMW"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-JGB7KL1LMW');
    `}
        </script>
        <title>Technology Blog Posts - Latest Tech News & Reviews</title>
        <meta
          name="description"
          content="Explore the latest in technology with our blog. Get the latest tech news, reviews, and tips on gadgets, software, and innovations."
        />
        <meta
          name="keywords"
          content="technology, tech news, gadgets, software, tech reviews, tech tips, innovations"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Technology Blog Posts - Latest Tech News & Reviews"
        />
        <meta
          property="og:description"
          content="Explore the latest in technology with our blog. Get the latest tech news, reviews, and tips on gadgets, software, and innovations."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="http://www.dailyblend.online/technology"
        />
        <meta
          property="og:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        <meta property="og:site_name" content="Daily Blend" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Technology Blog Posts - Latest Tech News & Reviews"
        />
        <meta
          name="twitter:description"
          content="Explore the latest in technology with our blog. Get the latest tech news, reviews, and tips on gadgets, software, and innovations."
        />
        <meta
          name="twitter:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Blog",
            url: "https://www.dailyblend.online/technology",
            name: "Technology Blog Posts - Latest Tech News & Reviews",
            description:
              "Explore the latest in technology with our blog. Get the latest tech news, reviews, and tips on gadgets, software, and innovations.",
            author: {
              "@type": "Person",
              name: "Daily Blend",
            },
            publisher: {
              "@type": "Organization",
              name: "Daily Blend",
              logo: {
                "@type": "ImageObject",
                url: "https://dailyblend.online/dailyBlendLogoF.png",
              },
            },
            image: "https://dailyblend.online/dailyBlendLogoF.png",
          })}
        </script>
        {structuredData.map((data, index) => {
          return (
            <script key={index} type="application/ld+json">
              {JSON.stringify(data)}
            </script>
          );
        })}
        <script
          async="async"
          data-cfasync="false"
          src="//pl23571129.highrevenuenetwork.com/87d6368575303aee4770457f47b672b7/invoke.js"
        ></script>
      </Helmet>

      <Container
        id="Recent"
        sx={{
          pt: { xs: 10, sm: 16 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "70%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h1" variant="h4" color="text.primary">
            Technology Blog Posts
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Welcome to our Tech Haven! 🚀 Dive into the exciting world of
            technology with us, where we bring you the latest and greatest in
            gadgets, software, and innovations. Whether you're a tech newbie or
            a seasoned pro, our easy-to-read articles, reviews, and tips will
            keep you in the loop and ahead of the curve. Join our community and
            stay connected with the future, today! 🌐✨
          </Typography>
        </Box>

        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Latest in Tech News
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Our technology blog is your go-to source for the latest in tech
                news. Stay informed about the newest developments in artificial
                intelligence, machine learning, blockchain technology, and more.
                We provide in-depth articles that cover the implications of
                these technologies on our daily lives, industries, and future
                trends.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                In-Depth Gadget Reviews
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Get the latest reviews on the newest gadgets hitting the market.
                From smartphones and laptops to smart home devices and wearable
                tech, our detailed reviews provide you with all the information
                you need to make informed purchase decisions. We break down the
                features, performance, and value of each product to help you
                choose the best tech for your needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Software and App Guides
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Explore our comprehensive guides on the latest software and
                apps. Whether you're looking for productivity tools, creative
                software, or the best apps for your smartphone, our guides offer
                step-by-step instructions, tips, and recommendations to help you
                get the most out of your tech.
              </Typography>
            </Box>
          </Grid>
          <div id="container-87d6368575303aee4770457f47b672b7"></div>
        </Grid>

        <Grid container spacing={2} mt={4}>
          {post.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
                <CardComponent
                  title={item.Title}
                  shortDescription={item.ShortDescription}
                  date={item.DatePosted}
                  author={item.AuthorName}
                  authorBio={item.AuthorBio}
                  content={item.Content}
                  authorDesc={item.AuthorDesc}
                  id={item.URL}
                  imageUrl={item.Image}
                />
              </Grid>
              {(index + 1) % 3 === 0 && (
                <Grid
                  item
                  xs={12}
                  key={`container-${index}`}
                  id={`container-${index}`}
                >
                  {/* Replace `key` and `id` as needed */}
                  {/* This div will be rendered after every 3rd CardComponent */}
                  <div id="container-87d6368575303aee4770457f47b672b7"></div>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Container>
    </>
  );
}
