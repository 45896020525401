import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function CardComponent({
  title,
  shortDescription,
  date,
  author,
  authorBio,
  imageUrl,
  id,
}) {
  const dateF = new Date(date);
  const month = monthNames[dateF.getMonth()];
  const day = dateF.getDate();
  const year = dateF.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  const maxWords = 30; // Maximum words to show initially
  const words = shortDescription?.split(" ");
  const trimmedDescription = words?.slice(0, maxWords)?.join(" ");
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/read-more/${id}`, {});
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        p: 1,
      }}
    >
      <CardHeader
        title={title}
        subheader={formattedDate}
        sx={{ padding: "4px" }}
      />
      {imageUrl && (
        <CardMedia
          component="img"
          height="194"
          image={imageUrl}
          alt={title}
          sx={{ objectFit: "cover" }}
        />
      )}
      <CardContent sx={{ paddingTop: "4px" }}>
        <Typography variant="body2" color="text.secondary">
          {trimmedDescription}

          <span
            onClick={handleNavigate}
            style={{ cursor: "pointer", color: "blue" }}
          >
            ...Read more
          </span>
        </Typography>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          pb: 2,
        }}
      >
        <Box>
          <CardHeader
            avatar={<Avatar alt={author} src="/static/images/avatar/1.jpg" />}
            title={author}
            subheader={authorBio}
          />
        </Box>
        <RWebShare
          data={{
            title: title,
            text: `Check out this article by ${author} on DailyBlend: ${title}`,
            url: `https://dailyblend.online/${id}`,
          }}
        >
          <ShareIcon sx={{ cursor: "pointer" }} />
        </RWebShare>
      </Box>
    </Card>
  );
}
