import React from "react";
import { Box, Container } from "@mui/material";
import AppBarCustom from "../components/AppBarCustom";
import "../css/Home.css";
import Hero from "../components/Hero";
import Recents from "./Recents";
import { alpha } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  heroContainer: {
    flex: "1 0 auto",
  },
  footer: {
    flexShrink: 0,
  },
};

export default function Home() {
  const location = useLocation();
  return (
    <Box sx={styles.mainContainer}>
      <AppBarCustom />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? "linear-gradient(180deg, #CEE5FD, #FFF)"
              : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container sx={styles.heroContainer}>
          {location.pathname === "/" && (
            <>
              <Hero />
              <Recents />
            </>
          )}
          <Outlet />
        </Container>
      </Box>
      <Footer
        title="About Us"
        description="Discover the story behind our blog, our mission, and the passionate team driving it. We aim to bring you the latest insights and trends in technology"
        sx={styles.footer}
      />
    </Box>
  );
}
