import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardComponent from "../components/CardComponent";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";

export default function Gadgets() {
  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    fetch("https://www.dailyblend.online/api/gadgetsPosts")
      .then((res) => res.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  if (posts.length === 0) return <div>Loading...</div>;

  const structuredData = posts.map((item) => ({
    "@context": "https://schema.org",
    "@type": "Gadgets Review",
    itemReviewed: {
      "@type": "Product",
      name: item.Title,
      image: item.Image,
      description: item.ShortDescription,
      brand: {
        "@type": "Brand",
        name: "Daily Blend",
      },
    },
    author: {
      "@type": "Person",
      name: item.AuthorName,
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: item.RatingValue || 5,
      bestRating: item.BestRating || 5,
      worstRating: item.WorstRating || 1,
    },
    reviewBody: item.Content,
  }));

  return (
    <>
      <Helmet>
        <title>Latest Gadgets Reviews - Gadgets News & Updates</title>
        <meta
          name="description"
          content="Stay updated with the latest gadgets reviews. Get insights, tips, and news on smartphones, laptops, smart home devices, wearables, and more."
        />
        <meta
          name="keywords"
          content="gadgets reviews, latest gadgets, smartphones, laptops, wearables, smart home devices, tech reviews"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Latest Gadgets Reviews - Gadgets News & Updates"
        />
        <meta
          property="og:description"
          content="Stay updated with the latest gadgets reviews. Get insights, tips, and news on smartphones, laptops, smart home devices, wearables, and more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.dailyblend.online/gadgets"
        />
        <meta
          property="og:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        <meta property="og:site_name" content="Daily Blend" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Latest Gadgets Reviews - Gadgets News & Updates"
        />
        <meta
          name="twitter:description"
          content="Stay updated with the latest gadgets reviews. Get insights, tips, and news on smartphones, laptops, smart home devices, wearables, and more."
        />
        <meta
          name="twitter:image"
          content="https://dailyblend.online/dailyBlendLogoF.png"
        />
        {structuredData.map((data, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(data)}
          </script>
        ))}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JGB7KL1LMW"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-JGB7KL1LMW');
    `}
        </script>
        <script
          async="async"
          data-cfasync="false"
          src="//pl23571129.highrevenuenetwork.com/87d6368575303aee4770457f47b672b7/invoke.js"
        ></script>
      </Helmet>

      <Container
        id="Recent"
        sx={{
          pt: { xs: 10, sm: 16 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "70%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h1" variant="h4" color="text.primary">
            Latest Gadgets Reviews
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Stay updated with the latest gadgets reviews and updates with us.
            From smartphones to smart home devices, stay informed with detailed
            reviews and insights to help you choose the best tech for your
            needs.
          </Typography>
        </Box>

        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Latest Smartphone Reviews
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Stay updated with our reviews on the newest smartphones in the
                market. From flagship models to budget-friendly options, find
                the perfect smartphone for your needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Latest Laptop Reviews
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Discover our reviews on the latest laptops available. Whether
                you need a powerful workstation or a portable ultrabook, we've
                got you covered with detailed insights and comparisons.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { sm: "left", md: "center" } }}>
              <Typography
                component="h2"
                variant="h5"
                color="text.primary"
                gutterBottom
              >
                Smart Home Devices Reviews
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                Explore our reviews on smart home devices. From smart speakers
                to security cameras, find out how these gadgets can make your
                home more efficient and connected.
              </Typography>
            </Box>
          </Grid>
          <div id="container-87d6368575303aee4770457f47b672b7"></div>
        </Grid>

        <Grid container spacing={2} mt={4}>
          {posts.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
                <CardComponent
                  title={item.Title}
                  shortDescription={item.ShortDescription}
                  date={item.DatePosted}
                  author={item.AuthorName}
                  authorBio={item.AuthorBio}
                  index
                  content={item.Content}
                  authorDesc={item.AuthorDesc}
                  id={item.URL}
                  imageUrl={item.Image}
                />
              </Grid>
              {(index + 1) % 3 === 0 && index !== posts.length - 1 && (
                <Grid item xs={12} key={`container-${index}`}>
                  {/* This div will be rendered after every 3rd CardComponent, but not after the last one */}
                  <div id={`container-${index}`}></div>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Container>
    </>
  );
}
